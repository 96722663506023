import * as React from "react"
import styled from 'styled-components';

import Wrapper from "../components/site/Wrapper";
import { Link } from "gatsby"
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  margin: 0.5rem;
`;

const Main = styled.main`
  flex-grow: 1;
  margin: 1rem 0;
  border: 1px solid grey;
`;

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const StyledLink = styled(Link)`
  margin: 0.5rem 0;
`;

// markup
const NotFoundPage = () => {
  return (
    <Wrapper>
    <PageWrapper>

      <Main>
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />

        <StyledLink to="/">Go home</StyledLink>.
      </p>
    </main>
    </Main>
  
    </PageWrapper>
    </Wrapper>
      
  )
}

export default NotFoundPage
